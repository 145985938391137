export default {
  methods: {
    assignListData(e) {
      e.label = `${e.no}. ${e.name}`;
      e.tags = e.tags || [];
      // this.adjustElapsed(e); // server side 에서 최초 수행
    },
    adjustElapsed(obj) {
      obj.elapsed_arr = (obj._elapsed || []).slice().sort((a, b) => a.elapsed - b.elapsed);
      if (obj.elapsed_arr.length) {
        obj.elapsedStat = {
          cnt: obj.elapsed_arr.length,
          min: obj.elapsed_arr[0].elapsed,
          avg: this.$utils.round(obj.elapsed_arr.map(e => e.elapsed).reduce((a, b) => a + b, 0) / obj.elapsed_arr.length, 3),
          max: obj.elapsed_arr.slice(-1)[0].elapsed,
        }
      }
    },
    chartCloneLambda(e) {
      return {
        name: e.name,
        sheetIdx: e.sheetIdx,
        filter: e.filter,
        type: e.type,
        series: e.series,
        labelCol: e.labelCol,
        seriesCols: e.seriesCols,
        dataCols: e.dataCols,
        includeCols: e.includeCols,
        excludeCols: e.excludeCols,
        dataCol: e.dataCol,
        selector: e.selector,
        script: e.script,
        style: e.style,
        class: e.class,
        opts: {...e.opts},
      };
    },
  }
}
