<script>
import {PolarArea} from 'vue-chartjs'
import { CustomTooltips } from '@/views/charts/CustomTooltips.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: PolarArea,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    chartdata: function () {
      this._chart && this._chart.destroy();
      this.render();
    }
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.render()
  },
  methods: {
    render() {
      const plugins = {
        colorschemes: {
          scheme: 'tableau.Tableau20',
        },
        datalabels: {
          display: false,
        },
        ...(this.options || {}).plugins
      };
      this.renderChart(this.chartdata, {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            // labelColor: function (tooltipItem, chart) {
            //   return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            // }
          }
        },
        ...this.options,
        plugins
      });
    }
  }
}
</script>
