<template>
  <div>
    <b-card v-if="obj">
      <div class="clearfix" v-if="mode !== 'view'">
        <div class="pull-right" v-if="obj.no">
          <i v-if="$R('DEV')" class="fa fa-2x fa-copy text-success pointer mr-2" @click="copyScript(obj)"></i>
          <i class="fa fa-2x fa-window-maximize text-info pointer mr-2" @click="$utils.open(`/#/data/store/${obj.no}?mode=view`)"></i>
          <i class="fa fa-2x fa-external-link text-info pointer mr-2" @click="$utils.open(`/#/pages/data/store/${obj.no}?mode=view`)"></i>
          <i v-if="favorite[obj.no]" class="fa fa-2x fa-star text-warning pointer" @click="removeFavorite"></i>
          <i v-else class="fa fa-2x fa-star-o text-warning pointer" @click="addFavorite"></i>
        </div>
        <h4><span v-if="obj.no && mode !== 'view'">{{obj.no}}. </span>{{obj.name}}</h4>
      </div>
      <div class="position-relative" v-if="mode === 'view'">
        <div class="position-absolute" style="top: 0; right: 0" v-if="obj.chartOnly && obj._elapsed && obj._elapsed.length">
          최종 갱신 시각 : {{obj._elapsed[obj._elapsed.length - 1].signature._dt}}
        </div>
        <div class="text-center mb-4 font-weight-bold">
          <h3>{{obj.no}}. {{obj.name}}</h3>
        </div>
      </div>

      <div v-if="obj.alert && (mode !== 'view' || !obj.chartOnly)" class="p-3 mb-2" style="background-color: #e7f8ef" v-html="txt2html(obj.alert)"></div>
      <div v-if="obj.desc && $R('DEV')" class="bg-gray-100 p-3 mb-2" v-html="txt2html(obj.desc)"></div>

      <div class="py-2 px-3 my-2" style="border: 1px solid #ccc" v-if="obj.params && obj.params.length && (mode !== 'view' || !obj.chartOnly)">
        <b-row class="">
          <template v-for="p in obj.params">
            <b-col class="pb-1" v-if="p.type === 'string'" cols="6" md="3">
              <div>
                <small>
                  <span v-if="p.required" class="text-danger">[필수] </span>
                  <span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - String
                </small>
              </div>
              <b-input :name="p.name" v-model="params[p.name]" :placeholder="p.placeholder" :title="p.placeholder"></b-input>
            </b-col>
            <b-col class="pb-1" v-if="p.type === 'password'" cols="6" md="3">
              <div>
                <small>
                  <span v-if="p.required" class="text-danger">[필수] </span>
                  <span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - Password
                </small>
              </div>
              <b-input :name="p.name" type="password" v-model="params[p.name]" :placeholder="p.placeholder" :title="p.placeholder"></b-input>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'number'" cols="6" md="3">
              <div>
                <small>
                  <span v-if="p.required" class="text-danger">[필수] </span>
                  <span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - Number
                </small>
              </div>
              <b-input :name="p.name" v-model.number="params[p.name]" :placeholder="p.placeholder" :title="p.placeholder"></b-input>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'select' && p.options" cols="6" md="3">
              <div>
                <small>
                  <span v-if="p.required" class="text-danger">[필수] </span>
                  <span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - Select
                </small>
              </div>
              <b-form-select :name="p.name" v-model="params[p.name]" :title="p.placeholder" :options="parseOptions(p.options)"></b-form-select>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'check' && p.options" cols="6" md="3">
              <div>
                <small>
                  <span v-if="p.required" class="text-danger">[필수] </span>
                  <span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - Checkbox
                </small>
              </div>
              <b-form-checkbox-group class="col-form-label" :name="p.name" v-model="params[p.name]" :title="p.placeholder" :options="parseOptions(p.options)">
              </b-form-checkbox-group>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'radio' && p.options" cols="6" md="3">
              <div>
                <small>
                  <span v-if="p.required" class="text-danger">[필수] </span>
                  <span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - Radio
                </small>
              </div>
              <b-form-radio-group class="col-form-label" :name="p.name" v-model="params[p.name]" :title="p.placeholder" :options="parseOptions(p.options)">
              </b-form-radio-group>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'date'" cols="6" md="3">
              <div>
                <small>
                  <span v-if="p.required" class="text-danger">[필수] </span>
                  <span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - Date
                </small>
              </div>
              <date-input v-model="params[p.name]" @change="v=>{params[p.name]=v}"></date-input>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'text'" cols="12" md="6">
              <div><small><span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - Text</small></div>
              <b-textarea v-model="params[p.name]" :placeholder="p.placeholder" :title="p.placeholder"></b-textarea>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'json'" cols="12" md="6">
              <div><small><span v-if="p.desc">{{p.desc}}({{p.name}})</span><span v-else>{{p.name}}</span> - JSON</small></div>
              <b-textarea v-model="params[p.name]" :placeholder="p.placeholder" :title="p.placeholder"></b-textarea>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'shop_preset'" cols="12">
              <shop-preset v-model="params[p.name]"></shop-preset>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'brand_preset'" cols="12" md="6">
              <brand-preset v-model="params[p.name]"></brand-preset>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'category_preset'" cols="12" md="6">
              <category-preset v-model="params[p.name]"></category-preset>
            </b-col>
            <b-col class="pb-1" v-else-if="p.type === 'date_from_to'" cols="12" md="6">
              <div><small>{{p.name}}</small></div>
              <date-from-to :from.sync="params[p.from]" :to.sync="params[p.to]" v-bind="{init: p.init || '1 week', absMonth: 12}"></date-from-to>
            </b-col>
          </template>
        </b-row>
      </div>
      <div v-if="mode !== 'view' || !obj.chartOnly" class="mb-2 clearfix">
        <b-form class="pull-left" inline>
          <template v-if="!obj.downOnly">
            <b-btn class="mr-2" variant="success" @click="runScript" :disabled="busy.run">실행<b-spinner class="ml-1" small v-if="busy.run"></b-spinner></b-btn>
            |
          </template>
          <b-btn class="mr-1" :class="obj.downOnly ? '' : 'ml-2'" :variant="runBeforeDown ? 'success' : 'secondary'" @click="downXlsx" :disabled="busy.xlsx">Xlsx<b-spinner class="ml-1" small v-if="busy.xlsx"></b-spinner></b-btn>
          <b-btn class="mr-1" :variant="runBeforeDown ? 'success' : 'secondary'" @click="downCsv" :disabled="busy.csv">csv<b-spinner class="ml-1" small v-if="busy.csv"></b-spinner></b-btn>
          <b-btn class="mr-2" :variant="runBeforeDown ? 'success' : 'secondary'" @click="downJSON" :disabled="busy.json">JSON<b-spinner class="ml-1" small v-if="busy.json"></b-spinner></b-btn>
          <template v-if="!obj.downOnly">
            <b-checkbox v-model="runBeforeDown">실행 후 다운</b-checkbox>
          </template>
        </b-form>
        <div class="pull-right">
          <a :href="`https://crontab.guru/#${obj.cron.replace(/ /g, '_')}`" target="_blank" class="badge alert-primary" v-if="obj.cron">자동실행 주기 : {{obj.cron}}</a>
        </div>
      </div>

      <template v-if="!(mode === 'view' && obj.chartOnly)">
        <div class="clearfix">
          <small class="pull-right" v-if="obj.elapsedStat && obj._elapsed">
              <span :title="obj._elapsed.slice(-30).reverse().map(e => `${e.signature._dt}] ${e.elapsed} s`).join('\n')">
                {{obj.elapsedStat.cnt === 50 ? '>= 50' : obj.elapsedStat.cnt}} 회 실행결과:
              </span>
            최소: {{obj.elapsedStat.min}} s,
            평균: {{obj.elapsedStat.avg}} s,
            최대: {{obj.elapsedStat.max}} s,
            <span :title="`${obj._elapsed.slice(-1)[0].elapsed} s`">{{obj._elapsed.slice(-1)[0].signature._dt.slice(5)}}</span>
            <i class="ml-1 fa fa-info-circle pointer" v-b-modal.elapsed></i>
          </small>
          <small>아래는 과거<span class="bold" v-if="sheetDt"> ({{sheetDt}}) </span>의 실행결과 샘플입니다(최대 10000 row) - 최신 결과는 다시 실행하시거나 xlsx 나 csv 로 다운로드 해주세요</small>
        </div>
        <b-modal v-if="obj._elapsed" id="elapsed" title="실행결과" size="lg" ok-only>
          <div v-for="e in obj._elapsed.slice(-1)" :key="e.elapsed">
            * Params: {{JSON.stringify(e.params)}}<br/>
            * 실행: {{e.signature._dt}}, {{e.signature._name}} at {{e.signature._ip}} ({{e.elapsed}} 초 소요)<br/>
            <template v-if="e.profile && typeof e.profile[0] === 'object'">
              * profile
              <pre class="bg-light p-2" v-text="e.profile.map(e => e.desc).join('\n')"></pre>
            </template>
          </div>
        </b-modal>
      </template>

      <template v-if="sheets && sheets.length && !(mode === 'view' && obj.chartOnly)">
        <b-tabs class="position-relative" v-model="tabIndex">
          <b-tab v-for="(sh, idx) of sheets" :title="sh.name || `Sheet ${idx + 1}`" :key="idx" style="padding:0">
            <!--          <b-btn class="mr-1 pull-right" variant="outline-success" @click="copyResult(idx)">Xlsx <i class="fa fa-copy"></i></b-btn>-->
            <hot-table ref="hotTable" :settings="sh.hotSettings || hotSettings"></hot-table>
          </b-tab>
          <span class="position-absolute" style="top: 15px; right:0">
          height:
          <b-badge v-for="h in ['300px', '500px', '800px', '80vh']" class="pointer" :variant="sheetHeight === h ? 'success' : 'light'"
                   @click="setSheetHeight(h)" :key="h">{{h.replace('px', '').replace('vh', '%')}}</b-badge>
        </span>
        </b-tabs>
        <small v-if="sheets && (mode !== 'view' || !obj.chartOnly) && sheets[tabIndex] && sheets[tabIndex].rows.length">
          <span v-if="sheetDt">[{{sheetDt}}]</span>
          {{sheets[tabIndex].rows.length}} rows
        </small>
      </template>
      <div v-else-if="!(mode === 'view' && obj.chartOnly) && !obj.downOnly" class="text-center p-5">
        실행결과가 없습니다.
      </div>

      <template v-if="obj.charts && obj.charts.length">
        <b-row>
          <b-col ref="chartCols" v-for="(c, idx) in obj.charts" v-if="
                  ['line', 'bar', 'radar'].includes(c.type) && (c.series === 'script' || c.labelCol && (c.series === 'row' && c.seriesCols || c.series === 'col' && c.dataCols)) ||
                  ['pie', 'doughnut', 'polar'].includes(c.type) && (c.series === 'script' || c.series === 'row' && c.labelCol && c.dataCol || c.series === 'col' && c.dataCols) ||
                  ['value'].includes(c.type)
                 "
                 class="mb-3" :class="c.class || 'col-6'" :key="idx">
            <line-chart v-if="c.type === 'line'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></line-chart>
            <bar-chart v-if="c.type === 'bar'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></bar-chart>
            <radar-chart v-if="c.type === 'radar'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></radar-chart>
            <pie-chart v-if="c.type === 'pie'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></pie-chart>
            <doughnut-chart v-if="c.type === 'doughnut'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></doughnut-chart>
            <polar-area-chart v-if="c.type === 'polar'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></polar-area-chart>
            <div v-if="c.type === 'value'" class="text-center" style="position: relative" :style="c.style">
              <div v-if="c.name">{{c.name}}</div>
              <div class="value_text d-inline-block" style="white-space: nowrap">{{c.data}}</div>
            </div>
          </b-col>
        </b-row>
      </template>

      <template v-if="mode !== 'view'">
        <hr/>
        <small class="text-muted">스크립트 생성: {{obj._cdt}} {{obj._cname}}</small>
      </template>
    </b-card>

    <iframe ref="file_frame" name="file_frame" style="width:1px;height:1px;visibility:hidden" @load="frameLoaded"></iframe>
    <form :action="$api.getHost() + '/data/store/xlsx'" ref="file_form" method="POST" target="file_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>
  </div>
</template>

<style>
.value_text {
  line-height: 1;
}
span.highlight {
  font-weight: bold;
  background-color: #ffff7e;
}
</style>

<script>

import dateInput from '@/views/modules/DateInput.vue'
import LineChart from '@/views/charts/Line.vue'
import BarChart from '@/views/charts/Bar.vue'
import HorizontalBarChart from '@/views/charts/HorizontalBar.vue'
import PieChart from '@/views/charts/Pie.vue'
import DoughnutChart from '@/views/charts/Doughnut.vue'
import PolarAreaChart from '@/views/charts/PolarArea.vue'
import RadarChart from '@/views/charts/Radar.vue'

import StoreMixin from '@/views/data/StoreMixin'

export default {
  name: "StoreView",
  mixins: [
    StoreMixin
  ],
  components: {
    dateInput,
    LineChart,
    BarChart,
    HorizontalBarChart,
    PieChart,
    DoughnutChart,
    PolarAreaChart,
    RadarChart,
  },
  props: ['mode', 'favorite', 'obj'],

  data() {
    return {
      params: {},
      sheets: [],
      sheetDt: '',
      tabIndex: 0,
      runBeforeDown: false,
      sheetHeight: '300px',
      busy: {run: false, xlsx: false, csv: false, json: false},

      hotSettings: {
        autoWrapCol: true,
        autoWrapRow: false,
        colHeaders: ['data'],
        columns: [
          {data: 'data', readOnly: true},
        ],
        colWidths: [100],
        width: '100%',
        height: 300,
        rowHeaders: true,
        licenseKey: 'non-commercial-and-evaluation',
        columnSorting: true,
        stretchH: "all",
        manualColumnResize: true,
      },
      chartStyle: 'max-height: 300px',
      frameCallback: () => {},
    }
  },
  created() {
  },
  sockets: {
    dataStoreXlsxDone() {
      this.busy.xlsx = false;
    },
    dataStoreCsvDone() {
      this.busy.csv = false;
    },
    dataStoreJsonDone() {
      this.busy.json = false;
    },
    async dataStoreExecuted(no) {
      if (no && this.obj && this.obj.no === no && this.mode === 'view') {
        const {sheets, dt} = await this.loadLastResult(no);
        if (sheets) {
          const elapsed = await this.loadLastElapsed(no);
          this.obj._elapsed.push(elapsed[0]);
          this.adjustElapsed(this.obj);

          this.sheets = sheets;
          this.sheetDt = dt;
          this.setHotTable();
          if (this.obj.charts.length) await this.assignChartData();
          console.log('refreshed');
        } else {
          this.sheets = [];
          this.sheetDt = '';
        }
      }
    },
    // 실시간 log 를 받아서 출력
    // 세션 및 txid 를 고려한다. 현재 창에만 띄운다.
    'console.log': (args) => {
      console.log(...args);
    },
    'console.pclog': (args) => {
      console.pclog(...args);
    },
    'console.error': (args) => {
      console.error(...args);
    },
  },
  watch: {
    tabIndex(v) {
      setTimeout(() => this.$refs.hotTable && this.$refs.hotTable[v] && this.$refs.hotTable[v].hotInstance.render(), 0);
    },
    busy: {
      deep: true,
      handler(v) {
        this.$emit('setBusy', 'run', v.run);
      }
    }
  },
  methods: {
    async addFavorite() {
      const j = await this.$api.postJson('/data/store/favorite/add', {no: this.obj.no});
      if (j) this.favorite[this.obj.no] = this.obj;
      this.$forceUpdate();
    },
    async removeFavorite() {
      const j = await this.$api.postJson('/data/store/favorite/remove', {no: this.obj.no});
      if (j) delete this.favorite[this.obj.no];
      this.$forceUpdate();
    },

    async loadScript(no) {
      const j = await this.$api.getJson(`/data/store/loadScript?no=${no}`);
      if (j) {
        this.selectScript(j.list[0]);
        this.$forceUpdate();
      }
    },
    async resetScript() {
      // obj 는 부모에서 리셋된다
      this.params = {};
      this.sheets = [{rows: [{}], keys: ['data']}];
      this.sheetHeight = '300px';
      this.setHotTable();
    },
    async selectScript(obj) {
      this.$emit('update:obj', obj);
      if (obj) {
        this.assignListData(obj);
        if (this.favorite[obj.no]) this.favorite[obj.no] = obj;
        window.storeObj = obj;
        if (this.mode !== 'view') history.replaceState(null, null, location.origin + '/#/data/store/' + obj.no);
        if (obj.downOnly) this.runBeforeDown = true;

        this.params = {};

        const $this = this; // preScript 에서 getJson 등을 쓸 수 있다
        if (obj.preScript) {
          try {
            await eval(`(async function f() {${obj.preScript}})`)();
          } catch (e) {
            this.$alertTop('프리스크립트에서 에러가 발생했습니다', {variants: 'danger'});
            console.error(e);
          }
        }

        obj.params && obj.params.forEach(e => {
          if (['string', 'password', 'select', 'radio', 'text', 'json'].includes(e.type)) this.params[e.name] = e.default || '';
          else if (e.type === 'number') this.params[e.name] = e.default ? +e.default : '';
          else if (e.type === 'date') this.params[e.name] = '';
          else if (e.type === 'check') this.params[e.name] = e.default ? e.default.split(',') : [];
          else if (e.type.endsWith('preset')) this.params[e.name] = [];
          else this.params[e.name] = null;
        });
        obj.charts.forEach(c => {
          if (!c.opts) c.opts = {};
        });

        this.sheets = [];
        const {sheets, dt} = await this.loadLastResult(obj.no);
        if (sheets) {
          this.sheets = sheets;
          this.sheetDt = dt;
          this.setHotTable();
          if (obj.charts.length) await this.assignChartData();
        } else {
          this.sheets = [];
          this.sheetDt = '';
        }
      }
    },
    async loadLastResult(no) {
      const j = await this.$api.getJson(`/data/store/lastResults?no=${no}`);
      if (j && j.result[no]) {
        return {sheets: j.result[no].map(sh => ({...sh, rows: sh.rows.slice(0, 10000)})), dt: j.dtMap[no]};
      } else {
        return {};
      }
    },
    async loadLastElapsed(no) {
      const j = await this.$api.getJson(`/data/store/lastElapsed?no=${no}`);
      if (j && j.elapsed[no]) {
        return j.elapsed[no];
      }
    },
    /**
     * select, check, radio 타입의 파라미터의 옵션값을 파싱해서 객체화하여 반환한다.
     *
     * @param {string} options      A,B 혹은 [{text,value}, ...] 형태의 JSON string
     * @returns {object[]}          [{text,value}, ...] 형태의 객체
     */
    parseOptions(options) {
      try {
        return JSON.parse(options);
      } catch (e) {
        return options.split(',').map(e => ({text: e, value: e}));
      }
    },
    parseParams() {
      const paramMap = {};
      for (const {type, name, from, to, desc, required} of this.obj.params) {
        if (type === 'shop_preset') {
          paramMap[name] = this.params[name].map(e => e.shop_id);
        } else if (type === 'brand_preset') {
          paramMap[name] = this.params[name].map(e => e.brand_no);
        } else if (type === 'category_preset') {
          paramMap[name] = this.params[name].map(e => e.category);
        } else if (type === 'date_from_to') {
          paramMap[from] = this.params[from];
          paramMap[to] = this.params[to];
        } else if (type === 'json') {
          try {
            paramMap[name] = eval(this.params[name]);
          } catch(e) {
            return alert('json 형식을 확인해주세요' + e.message);
          }
        } else if (type === 'check') {
          paramMap[name] = this.params[name] || [];
        } else {
          paramMap[name] = this.params[name];
        }
        if (required && (this.params[name] === '' || this.params[name] == null || type === 'check' && this.params[name].length === 0)) {
          return alert('필수값을 입력해주세요: ' + (desc || name));
        }
      }
      return paramMap;
    },
    async runScript() {
      // 권한체크
      if (this.obj.roles.length && !this.$R(this.obj.roles)) {
        return alert('실행에 권한이 필요합니다');
      }
      // 사용자 체크
      if (this.obj.users.length && !this.obj.users.map(e => e.value).includes(this.$S.user.id)) {
        return alert('특정 사용자만 실행할 수 있습니다');
      }

      const params = this.parseParams();
      if (!params) return;

      const {no, script, libs} = this.obj;
      // if (no) {
      //   if (!await this.save()) return;
      // }
      this.busy.run = true;
      const j = await this.$api.postJson('/data/store/run', {obj: {no, script, libs}, params, ioId: this.$io.io.id});
      this.busy.run = false;
      if (j) {
        if (j.ok === -1) {
          this.$modal.show({title: '다음 에러를 확인해주세요: ' + j.error, html: j.msg.replace(/ /g,'&nbsp;').replace(/\n/g,'<br/>')
              .replace('<highlight>', '<span class="highlight">').replace('</highlight>', '</span>') +
              (j.data ? `<br/><pre>${typeof j.data === 'object' ? JSON.stringify(j.data, null, 2) : j.data}</pre>` : '')
          });
          return;
        }
        this.sheets = j.sheets;
        this.setHotTable();
        if (this.obj.charts.length) {
          await this.assignChartData();
        }
        if (j.elapsed && this.obj._elapsed) {
          this.obj._elapsed.push(j.elapsed);
          this.adjustElapsed(this.obj);
        }
      }
    },
    setHotTable() {
      let idx = 0;
      const height = this.sheetHeight = this.obj && this.obj.no ? localStorage.getItem(`DS|${this.obj.no}|sheetHeight`) || '300px' : '300px';

      for (const sh of this.sheets) {
        let {rows, keys} = sh;
        if (rows && rows.length && !keys) {
          keys = this.makeFields(rows);
          rows = rows.slice(0, 10000);
          this.encodeObject(rows);
        } else if (rows && rows.length) {
          if (keys.includes('*')) { // a, b, * 등이라면 a, b 는 순서에 맞춰서, 나머지는 이후 이어서 보여준다.
            const allKeys = this.makeFields(rows);
            keys.splice(keys.indexOf('*'), 1, ...allKeys.filter(k => !keys.includes(k)));
          }
          rows = rows.slice(0, 10000);
          this.encodeObject(rows);
        } else if (!rows.length && !keys) {
          keys = ['data'];
        }
        sh.keys = keys;
        sh.rows = rows;

        const hs = this.$utils.clone(this.hotSettings);
        hs.colHeaders = keys;
        hs.columns = keys.map(e => ({data: e, readOnly: true}));
        hs.colWidths = this.calcMaxWith(rows, keys);
        // console.log(hs.colHeaders, hs.colWidths)
        hs.height = height;

        const i = idx++;
        setTimeout(() => {
          if (!this.$refs.hotTable || !this.$refs.hotTable[i]) return;
          const hi = this.$refs.hotTable[i].hotInstance;
          hi.updateSettings(hs);
          hi.loadData(rows);
        }, 0);
      }
    },
    makeFields(data) {
      const dupRow = {};
      data.forEach(row => Object.assign(dupRow, row));
      return Object.keys(dupRow);
    },
    encodeObject(data) {
      data.forEach(row => {
        Object.entries(row).forEach(([k, v]) => {
          if (typeof v === 'object') {
            row[k] = JSON.stringify(v);
          }
        });
      });
    },
    calcMaxWith(data, keys) {
      const maxLen = {};
      keys.forEach(k => maxLen[k] = this.getMaxLineLength(k) / 1.5); // 컬럼 헤더가 길어서 데이터보다 커보이는 것을 어느 정도 방지하기 위해 1.5로 나눈다

      const inc = Math.ceil(data.length / 300); // 300 개의 표본
      for (let i = 0; i < data.length; i += inc) {
        const row = data[i];
        keys.forEach(k => {
          maxLen[k] = Math.max(this.getMaxLineLength(row[k]), maxLen[k]);
        });
      }
      // grid 에서 숫자 11자 -> 92, 숫자 10자 -> 85 => x * 7 + 15
      // 847. EDIT COMPANY -> 17자 -> 137 => 17 * 7 + 15 = 134, 영문 등의 차이 있음, 8 로 조정
      const widths = keys.map(k => maxLen[k] * 8 + 10);

      // fhd 기준 grid 데이터 부분의 width = 1575, scroll 방지를 위해 1560 정도로 잡는다.
      // len 의 sum 이 이를 초과할 때, 기대평균을 초과한 cell 들에 대해 안분배당으로 width 를 감소시킨다.
      const widthSum = widths.sum();
      let targetWidth = 1560;
      if (widthSum > targetWidth) {
        let avg = targetWidth / keys.length;
        // 컬럼이 너무 많아서 대부분 over 할 경우 전체적으로 너무 쪼그라든다. over 율이 60% 를 넘는다면 targetWidth 기준을 완화한다.
        if (widths.filter(w => w > avg).length / widths.length > 0.8) {
          targetWidth += (widthSum - targetWidth) / 3 * 2;
          avg = targetWidth / keys.length;
        }
        const overColIndex = widths.map((w, i) => w > avg ? i : null);
        const overColSum = widths.filter(w => w > avg).sum();
        const targetRatio = (targetWidth - (widthSum - overColSum)) / overColSum;

        overColIndex.filter(e => e != null).forEach(i => widths[i] *= targetRatio);
      }
      return widths;
    },
    getMaxLineLength(s) {
      if (s == null) return 0;
      s += '';
      let max = 0;
      for (const line of s.split(/\r?\n/)) {
        let b = 0;
        // for (let i = 0, c; (c = line.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1); // byte 로 할 경우
        for (let i = 0, c; (c = line.charCodeAt(i++)); b += c >> 11 ? 2 : c >> 7 ? 2 : 1); // 한글을 2자 범위로 할 경우
        max = Math.max(max, b);
      }
      return max;
    },

    async assignChartData() {
      const datalabelsBase = {
        // borderColor: 'white',
        // borderRadius: 5,
        // borderWidth: 2,
        // backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: true,
        textShadowColor: 'rgba(255, 255, 255, 0.8)',
        textShadowBlur: 3,
        textStrokeWidth: 0.5,
        color: 'rgba(0, 0, 0, 0.8)',
        anchor: 'end',
        // align: 'center',
        // offset: 0,
      };

      // this.obj.charts, rows, keys || this.makeFields(rows)
      // 차트별로 데이터를 채워넣는다.
      const $this = this; // value type 의 selector 를 위해 마련해둔다
      for (const c of this.obj.charts) { // c = {sheetIdx: '', type: '', series: 'row', labelCol: '', seriesCols: '', opts, ...}
        // console.log(eval('this')); // for 안에서는 eval 로 this 를 가져올 수 없다

        let sheet;
        if (!c.sheetIdx) sheet = this.sheets[0];
        else if (!isNaN(c.sheetIdx)) sheet = this.sheets[+c.sheetIdx];
        else sheet = this.sheets.find(e => e.name === c.sheetIdx);
        if (!sheet) {
          if (c.type === 'value') {
            c.data = 'No Data';
          } else {
            c.data = {labels: ['데이터없음'], datasets: [{label: '데이터없음', data: []}]};
          }
          continue;
        }
        let rows = sheet.rows.slice();
        if (c.filter) {
          rows = eval(c.filter);
        }

        if (c.type === 'value') {
          if (!c.selector) {
            c.data = Object.values(rows[0])[0];
          } else {
            c.data = eval(c.selector);
          }
          if (typeof c.data === 'number') c.data = c.data.comma();
          continue;
        }
        const opts = c.opts || {};
        const chartOptions = {
          ...(c.name ? {title: {display: true, text: c.name}} : {}),
          ...(opts.legend != null ? {legend: {display: opts.legend}} : {}),
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
                modifierKey: 'ctrl',
              },
              zoom: {
                drag: {
                  enabled: true
                },
                mode: 'x',
              },
            },
          },
          // zoom: {
          //   // Boolean to enable zooming
          //   enabled: true,
          //   drag: true,
          //   mode: "x",
          //   // Zooming directions. Remove the appropriate direction to disable
          //   // Eg. 'y' would only allow zooming in the y direction
          // },
          // drag: {
          //   enabled: true,
          // }
        };

        if (!['value'].includes(c.type) && c.series === 'script') {
          c.data = {};
          c.options = {
            ...(c.name ? {title: {display: true, text: c.name}} : {}),
          };
          try {
            await eval(`(async function f(rows, sheets) {${c.script}})`)(rows, this.sheets);
          } catch (e) {
            this.$alertTop(`${c.name} Chart 스크립트에서 에러가 발생했습니다: ${e.message}`, {variants: 'danger'});
          }
        } else if (['line', 'bar', 'radar'].includes(c.type)) {
          const stack = c.type === 'bar' && opts.stack;
          const xIsTime = c.type === 'line' && opts.xIsTime;
          const beginAtZero = opts.zero;
          if (c.series === 'row') {
            const labels = rows.map(row => row[c.labelCol]);
            const series = c.seriesCols.split(',').map(e => e.trim());
            const axes = Array.from(new Set(series.map(e => e.split(':')[1] || 'A')));
            const datasets = series.map(e => {
              const [label, axis] = e.split(':');
              return {
                type: e.split(':')[2],
                label: label,
                ...(axes.length > 1 ? {yAxisID: axis || axes[0]} : {}),
                data: xIsTime ? rows.map(row => ({x: row[c.labelCol], y: row[label]})) : rows.map(row => row[label]),
                borderWidth: 2,
                ...(opts.radius != null ? {radius: opts.radius} : {}),
                ...(opts.datalabels ? {datalabels: datalabelsBase} : {}),
                fill: false,
              };
            });
            c.data = xIsTime ? {datasets} : {labels, datasets};
            c.options = {
              ...chartOptions,
              scales: {
                ...(stack || xIsTime ? {xAxes: [{
                    ...(stack ? {stacked: true} : {}),
                    ...(xIsTime ? {type: 'time', time: {unit: 'hour', displayFormats: {hour: 'MM-DD HH'}}} : {}),
                  }]} : {}),
                yAxes: axes.map((a, i) => ({
                  id: a,
                  type: 'linear',
                  position: i ? 'right' : 'left',
                  stacked: stack,
                  ...(beginAtZero ? {ticks: {beginAtZero}} : {}),
                  gridLines: {display: i === 0}
                }))
              }
            }
          } else if (c.series === 'col') {
            let keys = sheet.keys || this.makeFields(rows);
            if (c.dataCols === 'include') {
              keys = c.includeCols.split(',');
            } else if (c.dataCols === 'exclude') {
              const excludeCols = c.excludeCols ? c.excludeCols.split(',') : [];
              keys = keys.filter(e => !excludeCols.includes(e));
            }
            keys = keys.filter(e => e !== c.labelCol);
            const datasets = rows.map(e => ({
              label: e[c.labelCol],
              // backgroundColor: '#f87979',
              data: keys.map(l => e[l]),
              borderWidth: 2,
              ...(opts.radius != null ? {radius: opts.radius} : {}),
              ...(opts.datalabels ? {datalabels: datalabelsBase} : {}),
              fill: false,
            }));
            c.data = {labels: keys, datasets};
            c.options = {
              ...chartOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    ...(beginAtZero ? {ticks: {beginAtZero}} : {}),
                  }
                }]
              },
            };
          }

          c.options.scales.yAxes.forEach(e => {
            e.ticks = e.ticks || {};
            Object.assign(e.ticks, {
              userCallback(value) {
                return value.toLocaleString();   // this is all we need
              },
            });
          });
        } else if (['pie', 'doughnut', 'polar'].includes(c.type)) {
          if (c.series === 'row') {
            c.data = {labels: rows.map(e => e[c.labelCol]), datasets: [{data: rows.map(e => e[c.dataCol])}]};
            c.options = {
              ...chartOptions,
            };
          } else if (c.series === 'col') {
            // 첫 번째 row 만 사용한다. 여러 row 가 있다면 filter 가 지정되어야 한다.
            const row = rows[0];
            let keys = Object.keys(row);
            if (c.dataCols === 'include') {
              keys = c.includeCols.split(',');
            } else if (c.dataCols === 'exclude') {
              const excludeCols = c.excludeCols ? c.excludeCols.split(',') : [];
              keys = keys.filter(e => !excludeCols.includes(e));
            }
            c.data = {labels: keys, datasets: [{
                data: keys.map(e => row[e]),
                ...(opts.datalabels ? {datalabels: datalabelsBase} : {}),
              }]};
            c.options = {
              ...chartOptions,
            };
          }
          if (opts.sort) {
            // c.data.datasets[0].data.sort((a, b) => opts.sort === 'asc' ? a - b : b - a);
            const labels = c.data.labels;
            const data = c.data.datasets[0].data;
            const pair = labels.map((e, i) => [e, data[i]]);
            pair.sort((a, b) => opts.sort === 'asc' ? a[1] - b[1] : b[1] - a[1]);
            c.data.labels = pair.map(e => e[0]);
            c.data.datasets[0].data = pair.map(e => e[1]);
          }
        }

      }
      this.$forceUpdate();
      this.adjustValueWidth();
    },
    adjustValueWidth() {
      setTimeout(() => {
        // value type adjust
        this.obj.charts.forEach((c, i) => {
          if (c.type === 'value') {
            const colDiv = this.$refs.chartCols[i].lastElementChild;
            const parentWidth = colDiv.offsetWidth;
            const width = colDiv.lastElementChild.offsetWidth;
            const fontSize = colDiv.lastElementChild.computedStyleMap().get('font-size').value;

            let valueFontSize = '';
            if (c.style) {
              valueFontSize = (c.style.pick('value-font-size:', ';') || '').trim();
            }
            if (valueFontSize) {
              colDiv.lastElementChild.style.fontSize = valueFontSize;
            } else if (c.style && c.style.includes('height:')) { // height 속성이 있다면
              colDiv.lastElementChild.style.fontSize = Math.min(parseInt(parentWidth / width * fontSize * 0.95), this.$refs.chartCols[i].offsetHeight - 21) + 'px';
            } else {
              colDiv.lastElementChild.style.fontSize = parseInt(parentWidth / width * fontSize * 0.95) + 'px';
            }
            // console.log(parentWidth, width, fontSize, colDiv.lastElementChild.style.fontSize, colDiv.lastElementChild.innerHTML);
          }
        });
      }, 0);
    },

    downXlsx() {
      const params = this.runBeforeDown ? this.parseParams() : {};
      if (!params) return;
      this.busy.xlsx = true;
      this.frameCallback = () => { // 스크립트에서 에러가 날 때 이쪽 경로를 탄다.
        this.busy.xlsx = false;
      }
      const {no, name, script, libs} = this.obj;
      this.$refs.json_data.value = JSON.stringify({obj: {no, name, script, libs}, params, runBeforeDown: this.runBeforeDown});
      this.$refs.file_form.action = this.$api.getHost() + '/data/store/xlsx';
      this.$refs.file_form.submit();
    },
    downCsv() {
      // iframe 은 file down 시 onload 가 트리거되지 않는다. contents 가 바뀌지 않아서라 한다.
      // 파일 다운시 각종 on~~ 중에 onbeforeunload 만 반응한다. 그러나 이것도 post 가 시작된 직후 trigger 된다.
      // Object.entries(temp1.contentWindow).filter(e => e[0].startsWith('on')).forEach(([k,v]) => {if (!v) temp1.contentWindow[k] = e => console.log(k)})
      // this.$refs.file_frame.contentWindow.onbeforeunload = () => {
      //   console.log(this, this.busy.csv);
      //   this.busy.csv = false;
      // }
      const params = this.runBeforeDown ? this.parseParams() : {};
      if (!params) return;
      this.busy.csv = true;
      this.frameCallback = () => { // 스크립트에서 에러가 날 때 이쪽 경로를 탄다.
        this.busy.csv = false;
      }
      const {no, name, script, libs} = this.obj;
      this.$refs.json_data.value = JSON.stringify({obj: {no, name, script, libs}, params, runBeforeDown: this.runBeforeDown});
      this.$refs.file_form.action = this.$api.getHost() + '/data/store/csv';
      this.$refs.file_form.submit();
    },
    downJSON() {
      const params = this.runBeforeDown ? this.parseParams() : {};
      if (!params) return;
      this.busy.json = true;
      this.frameCallback = () => { // 스크립트에서 에러가 날 때 이쪽 경로를 탄다.
        this.busy.json = false;
      }
      const {no, name, script, libs} = this.obj;
      this.$refs.json_data.value = JSON.stringify({obj: {no, name, script, libs}, params, runBeforeDown: this.runBeforeDown});
      this.$refs.file_form.action = this.$api.getHost() + '/data/store/json';
      this.$refs.file_form.submit();
    },
    copyResult(idx) {
      const sh = this.sheets[idx];
      const text = [];
      text.push(sh.keys.map(e => `"${e.replace(/"/g, '""')}"`).join('\t'));
      sh.rows.map(row => {
        const rowTsv = sh.keys.map(c => row[c]).map(v => {
          if (['number', 'boolean'].includes(typeof v)) {
            return v;
          } else if (typeof v === 'string') {
            return v.replace(/"/g, '""');
          } else if (v == null) {
            return '';
          } else {
            return v;
          }
        }).join('\t');
        text.push(rowTsv);
      });
      this.$utils.copyAlert(text.join('\n'), {msg: '복사되었습니다. 엑셀에 붙여넣기 해주세요'});
    },
    frameLoaded() {
      if (this.frameCallback) {
        this.frameCallback();
        this.frameCallback = null;
      }
    },

    async copyScript(obj) {
      const newObj = {};
      'alert,chartOnly,noAuthHide,downOnly,name,params,roles,users,script,preScript,tags,menus,libs'.split(',').forEach(k => {
        if (obj[k] != null) newObj[k] = this.$utils.clone(obj[k]);
      });
      if (obj.charts && obj.charts.length) {
        const charts = obj.charts.map(this.chartCloneLambda);
        newObj.charts = charts;
      } else {
        newObj.charts = [];
      }
      this.selectScript(newObj);
    },

    setSheetHeight(h) {
      if (!this.$refs.hotTable || !this.$refs.hotTable.length) return;
      this.sheetHeight = h;
      this.$refs.hotTable.forEach(e => {
        e.hotInstance.updateSettings({height: h});
      });
      this.obj.no && localStorage.setItem(`DS|${this.obj.no}|sheetHeight`, h);
    },

    // obj.alert 부분을 적절히 html 화 하는 용도
    txt2html(txt) {
      const lines = txt.trim().split(/ *\r?\n/);
      return lines.map(line => {
        // block 요소가 아니라면 <br/> 을 추가
        if (!line.match(/<\/(address|article|aside|blockquote|canvas|dd|div|dl|dt|fieldset|figcaption|figure|footer|form)>$/) &&
          !line.match(/<\/(h[1-6]|header|hr|li|main|nav|noscript|ol|p|pre|section|table|tfoot|ul|video)>$/) &&
          !line.match(/<br\s*\/?>$/)) {
          line += '<br/>';
        }
        // & 를 &amp; 로 치환(url에 포함될 수 있어서 띄어쓰기가 이어질 때만), 2개 이상의 스페이스를 &nbsp; 로 치환, url 을 a 태그로 치환
        return line.replace(/& /g, '&amp; ').replace(/\s{2}/g, ' &nbsp;')
          .replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/g,
            e => line.includes('href="' + e) ? e : `<a href="${e}" target="_blank">${e}</a>`); // a 태그 안에 있는게 아닐 때만 replace
      }).join('');
    }
  }
}
</script>
